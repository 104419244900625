<template>
  <p class="ml-2" v-if="bldg_name">
    <NETVSIcon icon="building"/>
    {{ bldg_name }}
    <template v-if="room_name">
      <br/>└─
      <NETVSIcon icon="room"/>
      {{ room_name }}
    </template>
  </p>
</template>

<script>
import NETVSIcon from '@/icons/NETVSIcon.vue'

export default {
  name: 'ComponentLocation',
  components: {NETVSIcon},
  props: {
    bldg_name: {
      type: String,
      required: true
    },
    room_name: {
      type: String,
      required: true,
    },
  }
}
</script>
