<template>
  <div class="netdoc">
    <template v-if="module">
      <b-container fluid>
        <b-row>
          <b-col>
            <h1 class="my-4">
              {{ module.fq_name }} {{ module.name }}
              <small class="text-muted">
                {{ $t('views.nd.netdoc.module') }}
              </small>
            </h1>
          </b-col>
          <b-col>
            <div class="float-right" style="margin-top: 1.5rem">
              <b-button-group>
                <b-button variant="outline-secondary" @click="showEVLog()">
                  {{ $t('system.event_log') }}
                  <NETVSIcon icon="evlog"></NETVSIcon>
                </b-button>
                <b-button :to="'/netdoc/devices/' + device.fqdn" variant="outline-primary" v-if="device">
                  {{ $t('views.nd.netdoc.device') }}
                  <NETVSIcon icon="device" class="ml-1"/>
                </b-button>
                <b-button variant="outline-primary" @click="editModule(module)"
                          v-if="checkPermission('nd.rw:module')">
                  {{ $t('system.edit') }}
                  <NETVSIcon icon="edit"></NETVSIcon>
                </b-button>
              </b-button-group>
            </div>
          </b-col>
        </b-row>
        <b-row class="mt-n3">
          <b-col cols="3">
            <ComponentLocation :room_name="module.room" :bldg_name="module.bldg"/>
            <div v-if="device_records">
              <p v-for="ip of device_records" v-bind:key="ip.target_ipaddr" class="mt-n3">
          <span v-if="ip.target_ipaddr">
            {{ ip.target_ipaddr }}
          </span>
              </p>
            </div>
            <h5 v-if="module_attrs.length > 0">{{ $t('views.object_explorer.object_attributes') }}</h5>
            <table>
              <tr v-for="m in module_attrs" v-bind:key="m.object_gfk + '_' + m.ref_object_gfk">
                <td>
                  {{ $t_netdb(module_attr_def[m.ot_attr_def_key_word].description) }}
                </td>
                <td>
                  {{ m.value }}
                </td>
              </tr>
            </table>
          </b-col>
          <b-col cols="3">
            <div v-if="building">
              <div style="float: left;">
                <NETVSIcon icon="geo_address" class="pr-2"/>
              </div>
              <div style="float: left;">
                {{ building.name }}
                <br/>
                {{ building.street }}
                <br/>
                {{ building.postal_code }}
              </div>
            </div>
          </b-col>
          <b-col cols="6">
            <KITMap v-if="can_show_map" :center="building.geo_location" :zoom="17" style="height: 30vh; width: 100%;">
              <template v-slot:map_content>
                <l-marker :lat-lng="latLng(building.geo_location.lat, building.geo_location.lng)"
                          :icon="icon"></l-marker>
              </template>
            </KITMap>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" cols-lg="12">
            <b-card class="shadow mb-4" no-body v-if="submodules.length > 0">
              <b-card-header v-b-toggle:slots-collapse>
                <h5 class="d-inline">{{ $t('views.nd.modules.slot_assignments') }}</h5>
                <NETVSIcon class="collapse-icon" icon="collapse"></NETVSIcon>
              </b-card-header>
              <b-card-body body-class="p-0">
                <b-collapse id="slots-collapse" :visible="true">
                  <PaginatorTable striped :hide-filter="slot_table_fields.length < 10" :hide-top-pagination="true"
                                  :items="submodules" :fields="slot_table_fields" sort-by="slot_nbr">
                    <template v-slot:cell(slot_nbr)="data">
                      [{{ module_slots[data.item.parent_fq_slot].label }}]
                    </template>
                    <template v-slot:cell(slot_type_name)="data">
                      {{ module_slots[data.item.parent_fq_slot].type }}
                    </template>
                    <template v-slot:cell(submodule_type)="data">
                      <RouterLink :to="'/netdoc/modules/' + data.item.gpk">{{ data.item.type }}</RouterLink>
                    </template>
                    <template v-slot:cell(submodule_type_class)="data">
                      {{ submodule_types[data.item.type].class }}
                    </template>
                    <template v-slot:cell(slot_info)="data">
                      {{ module_slots[data.item.parent_fq_slot].description }}
                    </template>
                    <template v-slot:cell(local_port)="data">
                      <RouterLink :to="'/netdoc/modules/' + module_gpk + '/p_port/' + data.item.gpk">{{
                          data.item.name
                        }}
                      </RouterLink>
                    </template>
                    <template v-slot:cell(actions)="">
                    </template>
                  </PaginatorTable>
                </b-collapse>
              </b-card-body>
            </b-card>

            <b-card class="shadow mb-4" no-body>
              <b-card-header v-b-toggle:ports-collapse>
                <h5 class="d-inline">{{ $t('views.nd.modules.port_assignments') }}</h5>
                <NETVSIcon class="collapse-icon" icon="collapse"></NETVSIcon>
              </b-card-header>
              <b-card-body body-class="p-0">
                <b-collapse id="ports-collapse" :visible="true">
                  <PaginatorTable striped :items="local_p_ports_styled" :fields="p_port_table_fields"
                                  sort-by="local_port">
                    <template v-slot:cell(local_port)="data">
                      <NETVSIcon v-b-tooltip.hover.v-danger :title="$t('views.nd.modules.port_defective')" class="text-danger mr-1" icon="defective" v-if="data.item.is_defective" />
                      <RouterLink :to="'/netdoc/modules/' + module_gpk + '/p_port/' + data.item.gpk">{{
                          data.item.name
                        }}
                      </RouterLink>
                    </template>
                    <template v-slot:cell(actions)="data">
                      <b-button-group class="ml-1">
                        <b-button variant="outline-primary" @click="editPPort(data.item)" :id="'edit-pport-btn-' + data.item.name"
                                  v-if="checkPermission('nd.rw:p_port')">
                          <NETVSIcon icon="edit"></NETVSIcon>
                        </b-button>
                        <b-tooltip :target="'edit-pport-btn-' + data.item.name" triggers="hover" variant="primary" placement="bottom">
                          {{ $t('views.nd.modules.edit-pport') }}
                        </b-tooltip>
                        <b-button variant="outline-danger" @click="unlinkPPort(data.item)" :id="'unlink-pport-btn-' + data.item.name"
                                  v-if="p_port_ends_map[data.item.gpk] && checkPermission('nd.rw_connect')">
                          <NETVSIcon icon="unlink"></NETVSIcon>
                        </b-button>
                        <b-tooltip :target="'unlink-pport-btn-' + data.item.name" triggers="hover" variant="danger" placement="bottom"
                                   v-if="p_port_ends_map[data.item.gpk] && checkPermission('nd.rw_connect')">
                          {{ $t('views.nd.modules.unlink-pport') }}
                        </b-tooltip>
                        <b-button variant="outline-success" @click="linkPPort(data.item)" :id="'link-pport-btn-' + data.item.name"
                                  v-else-if="checkPermission('nd.rw_connect')">
                          <NETVSIcon icon="link"></NETVSIcon>
                        </b-button>
                        <b-tooltip :target="'link-pport-btn-' + data.item.name" triggers="hover" variant="success" placement="bottom">
                          {{ $t('views.nd.modules.link-pport')}}
                        </b-tooltip>
                        <b-button variant="outline-secondary" @click="showPortEVLog(data.item.gpk)" :id="'evlog-btn-' + data.item.name">
                          <NETVSIcon icon="evlog"></NETVSIcon>
                        </b-button>
                        <b-tooltip :target="'evlog-btn-' + data.item.name" triggers="hover" variant="secondary" placement="bottom">
                          {{ $t('system.event_log')}}
                        </b-tooltip>
                      </b-button-group>
                    </template>
                  </PaginatorTable>
                </b-collapse>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
    </template>
    <template v-else>
      <Loading :data="null"></Loading>
    </template>
    <b-modal id="module-map-modal" :title="$t('views.nd.bcd.connection_path')" size="xl">
      <b-row>
        <b-col>
          <b-card class="shadow-sm network-card" no-body>
            <!--            TODO: Map for module...-->
            <!--            <PPortNetwork v-if="map_module_gpk" :p_port_gpk="map_module_gpk"/>-->
          </b-card>
        </b-col>
      </b-row>
    </b-modal>
    <EVLogViewer v-if="ev_module_gpk" modal_id="module-evlog"
                 :title="$t('views.nd.netdoc.module')"
                 refobj_id_field="gpk"
                 :refobj_id_value="ev_module_gpk" ref_obj_fq="nd.module"></EVLogViewer>
    <EVLogViewer v-if="ev_port_gpk" modal_id="p_port-evlog"
                 :title="$t('views.nd.netdoc.p_port')"
                 refobj_id_field="gpk"
                 :refobj_id_value="ev_port_gpk" ref_obj_fq="nd.p_port"></EVLogViewer>
    <DBEditor modal_id="module_dbeditor" object_fq_name="nd.module" :object_function="object_function"
              :old_data="module_db_editor_old_data"
              :presets="module_db_editor_old_data"
              :non_optionals_order="['fq_name', 'description']"></DBEditor>
    <DBEditor modal_id="p_port_dbeditor" object_fq_name="nd.p_port" :object_function="object_function"
              :old_data="p_port_db_editor_old_data"
              :presets="p_port_db_editor_old_data"
              :non_optionals_order="['description', 'is_defective']"></DBEditor>
  </div>
</template>

<script>

import NETVSIcon from '@/icons/NETVSIcon.vue'
import Loading from '@/components/Loading.vue'
import EVLogViewer from '@/components/EVLogViewer.vue'
import netdocService from '@/api-services/netdoc.service'
import apiutil from '@/util/apiutil'
import DBEditor from '@/components/db-editor/APIObjectDBEditor.vue'
import PaginatorTable from '@/components/PaginatorTable.vue'
import {LMarker} from 'vue2-leaflet'// LTileLayer, LMarker } from 'vue2-leaflet';
import {icon, latLng} from 'leaflet'
import KITMap from '@/components/KITMap.vue'
import {EventBus} from '@/eventbus'
import ComponentLocation from '@/components/nd/ComponentLocation.vue'

export default {
  name: 'NetdocModule',
  components: {ComponentLocation, KITMap, PaginatorTable, EVLogViewer, NETVSIcon, Loading, DBEditor, LMarker},
  data() {
    return {
      module_gpk: undefined,
      module: undefined,

      local_p_ports: {},
      remote_p_ports: {},
      int_remote_p_ports: {},
      all_remote_p_ports: {},
      p_port_ends_map: {},
      module_attrs: [],
      module_attr_def: {},

      module_slots: {},
      submodules: [],
      submodule_types: {},

      device: undefined,
      device_records: undefined,

      building: undefined,
      room: undefined,

      ev_module_gpk: undefined,
      ev_port_gpk: undefined,
      ev_slot_gpk: undefined,

      object_function: undefined,
      module_db_editor_old_data: undefined,
      p_port_db_editor_old_data: undefined,

      icon: icon({iconUrl: '/location.png', iconSize: [32, 42], iconAnchor: [16, 42]})
    }
  },
  methods: {
    latLng(lat, lng) {
      return latLng(lat, lng)
    },
    checkPermission(perm) {
      return apiutil.checkPermission(this.$store.state, perm)
    },
    apiutil() {
      return apiutil
    },
    editModule(item) {
      this.module_db_editor_old_data = item
      this.object_function = 'update'
      this.$root.$emit('bv::show::modal', 'module_dbeditor')
    },
    editPPort(item) {
      this.p_port_db_editor_old_data = item
      this.object_function = 'update'
      this.$root.$emit('bv::show::modal', 'p_port_dbeditor')
    },
    unlinkPPort(item) {
      this.p_port_db_editor_old_data = item
      this.object_function = 'disconnect'
      this.$root.$emit('bv::show::modal', 'p_port_dbeditor')
    },
    linkPPort(item) {
      this.p_port_db_editor_old_data = item
      this.object_function = 'connect'
      this.$root.$emit('bv::show::modal', 'p_port_dbeditor')
    },
    showEVLog() {
      this.ev_module_gpk = this.module_gpk
      this.$root.$nextTick(() => {
        this.$root.$emit('bv::show::modal', 'module-evlog')
      })
    },
    showPortEVLog(port_gpk) {
      this.ev_port_gpk = port_gpk
      this.$root.$nextTick(() => {
        this.$root.$emit('bv::show::modal', 'p_port-evlog')
      })
    },
    show_map_for_module(module_gpk) {
      this.map_module_gpk = module_gpk
      this.$bvModal.show('module-map-modal')
    },
    async fetchData() {
      this.module = undefined
      this.module_gpk = this.$route.params.module_gpk
      this.$store.commit('setNavigationRefreshHandle', {gpk: this.module_gpk, objType: 'nd.module'})

      this.local_p_ports = {}
      this.remote_p_ports = {}
      this.int_remote_p_ports = {}
      this.all_remote_p_ports = {}
      this.p_port_ends_map = {}
      this.module_slots = {}
      this.submodules = []
      this.submodule_types = {}
      this.device = undefined
      this.device_records = undefined

      const result = await netdocService.resolve_module_remote_ports(this.$store.state, this.module_gpk)
      this.module = result.data.module_list[0]
      this.room = result.data.room_list[0]
      this.building = result.data.bldg_list[0]
      this.local_p_ports = apiutil.dict_by_value_of_array(result.data.local_p_port_list, 'gpk')
      this.remote_p_ports = apiutil.dict_by_value_of_array(result.data.remote_p_port_list, 'gpk')
      this.int_remote_p_ports = apiutil.dict_by_value_of_array(result.data.int_remote_p_port_list, 'gpk')
      this.all_remote_p_ports = Object.assign({}, this.remote_p_ports, this.int_remote_p_ports, apiutil.dict_by_value_of_array(result.data.int_local_p_port_list, 'gpk'))
      this.module_attrs = result.data.module_attr_list
      this.module_attr_def = apiutil.dict_by_value_of_array(result.data.module_attr_def, 'key_word')

      const mod_name = this.module.fq_name
      window.console.debug('module name:', mod_name)
      EventBus.$emit('overwrite_breadcrumbs', {name: () => mod_name})
      // magic to resolve destination p_ports
      for (const p of Object.values(this.local_p_ports)) {
        let search_id
        if (p.connection_id_gfk_list[0] === p.gpk) {
          search_id = p.connection_id_gfk_list.slice(-1)[0]
        } else if (p.connection_id_gfk_list.slice(-1)[0] === p.gpk) {
          // p is the end of a path => we'll search the start
          search_id = p.connection_id_gfk_list[0]
        } else {
          // p is neither start nor end
          // this means this port is a port looking from the module in the direction of the end
          // of the connection path. ==> dest_connected_gfk is ensured to contain one port of the destination module
          // this can than be resolved by the internal connection.
          this.p_port_ends_map[p.gpk] = this.all_remote_p_ports[p.dest_connected_gfk].internal_connected_gfk
        }
        if (p.connection_id_gfk_list.length === 1 && p.connection_id_gfk_list[0] === p.gpk) {
          // special case: port is only connected to itself => not connected.
          // No need to further search a target.
          continue
        }
        for (const remote_port of Object.values(this.remote_p_ports)) {
          if (remote_port.connection_id_gfk_list[0] === search_id) {
            if (remote_port.internal_connected_gfk) {
              this.p_port_ends_map[p.internal_connected_gfk] = remote_port.internal_connected_gfk
            } else {
              this.p_port_ends_map[p.internal_connected_gfk] = remote_port.gpk
            }
            break
          }
        }
      }

      const slot_result = await netdocService.get_submodule_slots_for_module(this.$store.state, this.module_gpk)
      this.module_slots = apiutil.dict_by_value_of_array(slot_result.data.slot_list, 'fq_name')
      this.submodules = slot_result.data.submodule_list
      this.submodule_types = apiutil.dict_by_value_of_array(slot_result.data.submodule_type_list, 'name')

      const device_result = await netdocService.get_device_for_module(this.$store.state, this.module_gpk)
      if (device_result.data.device_list.length > 0) {
        this.device = device_result.data.device_list[0]
        this.device_records = device_result.data.record_list
      }
    }
  },
  async created() {
    await this.fetchData()
  },
  computed: {
    local_p_ports_styled: function() {
      return Object.values(this.local_p_ports).map(p => {
        if (p.is_defective) {
          p._rowVariant = 'danger'
        }
        return p
      })
    },
    can_show_map: function () {
      if (this.building && this.building.geo_location) {
        if (this.building.geo_location.lat && this.building.geo_location.lng) {
          return true
        }
      }
      return false
    },
    slot_table_fields: function () {
      const result = [
        {
          label: this.$t('views.nd.modules.slot_number'),
          key: 'slot_nbr',
          sortable: true,
          sortByFormatted: true,
          formatter: (value, key, item) => this.module_slots[item.parent_fq_slot].label_sortby
        },
        {
          label: this.$t('views.nd.modules.slot_type_name'),
          key: 'slot_type_name',
          sortable: true,
          sortByFormatted: true,
          formatter: (value, key, item) => this.module_slots[item.parent_fq_slot].type
        },
        {
          label: this.$t('views.nd.modules.submodule_name'),
          key: 'label',
          sortable: true
        },
        {
          label: this.$t('views.nd.modules.submodule_type_name'),
          key: 'submodule_type',
          sortable: true,
          sortByFormatted: true,
          formatter: (value, key, item) => item.type
        },
        {
          label: this.$t('views.nd.modules.submodule_type_class'),
          key: 'submodule_type_class',
          sortable: true,
          sortByFormatted: true,
          formatter: (value, key, item) => this.submodule_types[item.type].class
        },
        {
          label: this.$t('views.nd.modules.slot_info'),
          key: 'slot_info',
          sortable: false
        },
        {
          label: this.$tc('system.action', 2),
          key: 'actions',
          sortable: false
        }
      ]
      return result
    },
    p_port_table_fields() {
      const result = [
        {
          label: this.$t('views.nd.modules.port_number'),
          key: 'local_port',
          sortable: true,
          sortByFormatted: true,
          formatter: (value, key, item) => item.name_sortby
        },
        {
          label: this.$t('views.nd.modules.port_type'),
          key: 'type',
          sortable: true
        },
        {
          label: this.$t('views.nd.modules.port_speed'),
          key: 'speed',
          sortable: true
        },
        {
          label: this.$t('views.nd.modules.port_protocol'),
          key: 'protocol',
          sortable: true
        },
        // TODO: this will not be implemented until api 4.1 is used. Would just be to much effort to implement this for 4.0
        // {
        //   label: this.$t('views.nd.modules.port_connected_with'),
        //   key: 'remote_port',
        //   sortable: false
        // },
        {
          label: this.$t('views.nd.modules.port_info'),
          key: 'description',
          sortable: false
        },
        {
          label: this.$tc('system.action', 2),
          key: 'actions',
          sortable: false
        }
      ]
      return result
    }
  },
  watch: {
    $route() {
      this.fetchData()
    }
  }
}
</script>

<style scoped>
.collapse-icon {
  display: inline;
  vertical-align: center;
  float: right;

  transition: transform;
  transition-duration: 250ms;
}

.not-collapsed > .collapse-icon {
  transform: rotate(-180deg);
}

</style>
